import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import ExecomCard from "../../components/Execom/ExecomCard";
import plus from "../../svgs/plus-pattern.png";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import "../../assets/css/styles.css";
// import required modules
import useFetch from "../../hooks/useFetch";
import { BASE_URL } from "../../constants/urls";
import LocalLoader from "../../components/LocalLoader";

// Import Swiper styles
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExecomPage = () => {
  const [search, setSearch] = useState("");
  const [currentYearIndex, setCurrentYearIndex] = useState(0);
  const [currentYearValue, setCurrentYearValue] = useState(2024);
  const [currentWebYearIndex, setCurrentWebYearIndex] = useState(0);
  // const [years,setYears] = useState([]);

  const [loading, setLoading] = useState(false);
  const {
    error,
    isPending,
    data: execom,
  } = useFetch(BASE_URL + "/commitee/execom", true);
  const {
    error_web_team,
    isPending: isPending_web_team,
    data: web_team,
  } = useFetch(BASE_URL + "/commitee/web_team", true);
  const {
    error_doc_team,
    isPending: isPending_doc_team,
    data: doc_team,
  } = useFetch(BASE_URL + "/commitee/teams/doc", true);
  const {
    error_media_team,
    isPending: isPending_media_team,
    data: media_team,
  } = useFetch(BASE_URL + "/commitee/teams/media", true);
  const {
    error_design_team,
    isPending: isPending_design_team,
    data: design_team,
  } = useFetch(BASE_URL + "/commitee/teams/design", true);

  const [filteredExecom, setFilteredExecom] = useState([]);
  const [filteredWebTeam, setFilteredWebTeam] = useState([]);
  const [filteredDocTeam, setFilteredDocTeam] = useState([]);
  const [filteredDesignTeam, setFilteredDesignTeam] = useState([]);
  const [filteredMediaTeam, setFilteredMediaTeam] = useState([]);

  //   var filteredExecom = [];
  //   var filteredWebTeam = [];
  //   var filteredDocTeam = [];
  //   var filteredDesignTeam = [];
  //   var filteredMediaTeam = [];

  useEffect(() => {
    if (execom) {
      setFilteredExecom(
        execom
          .find((team) => team.year === currentYearValue)
          ?.team.filter((member) =>
            search
              ? member.name.toLowerCase().includes(search.toLowerCase())
              : true
          ) || []
      );
    }
    if (web_team) {
      setFilteredWebTeam(
        web_team
          .find((team) => team.year === currentYearValue)
          ?.team.filter((member) =>
            search
              ? member.name.toLowerCase().includes(search.toLowerCase())
              : true
          ) || []
      );
    }
    if (doc_team) {
      setFilteredDocTeam(
        doc_team
          .find((team) => team.year === currentYearValue)
          ?.team.filter((member) =>
            search
              ? member.name.toLowerCase().includes(search.toLowerCase())
              : true
          ) || []
      );
    }

    if (design_team) {
      setFilteredDesignTeam(
        design_team
          .find((team) => team.year === currentYearValue)
          ?.team.filter((member) =>
            search
              ? member.name.toLowerCase().includes(search.toLowerCase())
              : true
          ) || []
      );
    }
    if (media_team) {
      setFilteredMediaTeam(
        design_team
          .find((team) => team.year === currentYearValue)
          ?.team.filter((member) =>
            search
              ? member.name.toLowerCase().includes(search.toLowerCase())
              : true
          ) || []
      );
    }
  }, [
    currentYearIndex,
    currentYearValue,
    execom,
    web_team,
    doc_team,
    design_team,
    search,
  ]);

  const handleChange = async (event, newValue) => {
    setLoading(true);
    setCurrentYearValue(execom[newValue]?.year);
    setCurrentYearIndex(newValue);
    setLoading(false);
  };

  useEffect(() => {
    if (execom !== null && web_team !== null) {
      setCurrentYearIndex(execom.length - 1);
      setCurrentWebYearIndex(web_team?.length - 1);
    }

    
  }, [execom, web_team]);

  return (
    <div
      data-aos="fade-zoom-in"
      data-aos-duration="300"
      className="w-screen overflow-x-hidden bg-white flex flex-col items-start min-h-screen"
    >
      <div className="bg-white overflow-hidden relative w-screen mt-20">
        <div className="text-start w-full sm:w-3/4 py-12 sm:px-6 lg:py-16 lg:px-8 z-20 ">
          <h2 className="text-3xl text-center sm:text-left lg:px-32 sm:px-36 font-extrabold text-black dark:text-black sm:text-4xl">
            <span className="block text-5xl font-semibold lg:text-7xl font-gillory">
              IEDC TEAM
            </span>
            <span className=" text-teal-600 text-3xl  hidden sm:block">
              It&#x27;s our team!
            </span>
            <Link
              to="/execom"
              className="text-black hover:text-gray-500 text-xl text-center"
            >
              <InfoIcon />
            </Link>
          </h2>
          <p className="text-xl lg:px-32 mt-4 text-gray-400 hidden sm:block">
            The soul of an organisation is its team who consistently works for
            its upliftment. IEDC TKMCE holds the most creative and innovative
            leaders to guide you to your dreams and visions.
          </p>
        </div>

        <img
          alt=""
          src={plus}
          className="absolute h-full hidden lg:block right-0 top-0"
        />
      </div>

      <Box sx={{ width: "100%", maxWidth: "100vw" }}>
        <Box display="flex" justifyContent="center" width="100%">
          <Tabs
            allowScrollButtonsMobile
            // textColor=
            TabIndicatorProps={{
              style: {
                backgroundColor: "red",
                color: "red",
              },
            }}
            indicatorColor="primary"
            // indicatorColor="secondary"
            variant="scrollable"
            value={currentYearIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {execom !== null &&
              execom.length > 0 &&
              execom.map((year, index) => (
                <Tab
                  //   key={index}
                  style={{
                    color: currentYearIndex === index ? "red" : "black",
                    fontWeight: "bold",
                    fontSize: "large",
                  }}
                  onClick={() => setCurrentYearValue(year.year)}
                  label={year.year}
                  {...a11yProps(currentYearIndex)}
                />
              ))}
            {error && <div className="p-2">{error}</div>}
            {/* <Tab style={{color:currentYearIndex===1?'white':'gray',}} label="TICKETS" {...a11yProps(1)} />
          <Tab style={{color:currentYearIndex===2?'white':'gray',}} label="PAYMENT" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>

        <TabPanel value={currentYearIndex} index={currentYearIndex}>
          {!loading ? (
            <div id="execomcomponent_id" className="w-full p-5">
              <div className=" text-border hover:border-dotted bg-white flex items-center justify-evenly py-4 w-full md:px-6">
                <div className="border rounded overflow-hidden flex w-full md:w-1/3">
                  <input
                    type="text"
                    className="px-4 py-2 w-full"
                    placeholder="Search "
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  <button className="flex items-center justify-center px-4 border-r">
                    <svg
                      className="h-4 w-4 text-grey-dark"
                      fill="#2B8C8C"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap mt-10 md:mt-5	px-6 place-content-center">
                {/* <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl 'w-full lg:text-5xl text-border">Executive Committee</h1> */}
                <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-10 justify-items-center w-full md:w-3/4">
                  {filteredExecom !== null &&
                    filteredExecom.length > 0 &&
                    filteredExecom.map((member, index) => (
                      <ExecomCard key={index} e={member} />
                    ))}
                </section>
                {isPending && (
                  <div className="p-2 w-full text-center text-black relative flex justify-center">
                    <LocalLoader />
                  </div>
                )}
                {error && (
                  <div className="p-2 w-full text-center text-black">
                    {error}
                  </div>
                )}
                {!error && filteredExecom?.length === 0 && !isPending && (
                  <div className="p-2 text-center text-black w-full">
                    No members.
                  </div>
                )}
              </div>
              {!error_web_team &&
                filteredWebTeam?.length !== 0 &&
                !isPending_web_team && (
                  <div className="flex flex-wrap mt-24 px-6 place-content-center">
                    {/* <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl 'w-full lg:text-5xl text-border">Executive Committee</h1> */}
                    <h2 className="mb-10 text-3xl font-extrabold tracking-tight leading-none md:text-4xl w-full lg:text-4xl text-teal-600 md:w-4/5">
                      Web Team
                    </h2>

                    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-10 justify-items-center w-full md:w-3/4">
                      {filteredWebTeam !== null &&
                        filteredWebTeam?.length > 0 &&
                        filteredWebTeam?.map((member, index) => (
                          <ExecomCard e={member} />
                        ))}
                    </section>
                    {isPending_web_team && (
                      <div className="p-2 w-full text-center text-black relative flex justify-center">
                        <LocalLoader />
                      </div>
                    )}
                    {error_web_team && (
                      <div className="p-2 w-full text-center text-black">
                        {error}
                      </div>
                    )}
                    {/* {!error_web_team && filteredDocTeam?.length === 0 && !isPending_web_team && <div className='p-2 text-center text-black w-full'>No members.</div>} */}
                  </div>
                )}
              {!error_doc_team &&
                filteredDocTeam?.length !== 0 &&
                !isPending_doc_team && (
                  <div className="flex flex-wrap mt-24 px-6 place-content-center">
                    {/* <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl 'w-full lg:text-5xl text-border">Executive Committee</h1> */}
                    <h2 className="mb-10 text-3xl font-extrabold tracking-tight leading-none md:text-4xl w-full lg:text-4xl text-teal-600 md:w-4/5">
                      Doc Team
                    </h2>

                    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-10 justify-items-center w-full md:w-3/4">
                      {filteredDocTeam !== null &&
                        filteredDocTeam?.length > 0 &&
                        filteredDocTeam?.map((member, index) => (
                          <ExecomCard e={member} />
                        ))}
                    </section>
                    {isPending_doc_team && (
                      <div className="p-2 w-full text-center text-black relative flex justify-center">
                        <LocalLoader />
                      </div>
                    )}
                    {error_doc_team && (
                      <div className="p-2 w-full text-center text-black">
                        {error}
                      </div>
                    )}
                    {/* {!error_doc_team && filteredDocTeam?.length === 0 && !isPending_doc_team && <div className='p-2 text-center text-black w-full'>No members.</div>} */}
                  </div>
                )}
              {!error_design_team &&
                filteredDesignTeam?.length !== 0 &&
                !isPending_design_team && (
                  <div className="flex flex-wrap mt-24 px-6 place-content-center">
                    {/* <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl 'w-full lg:text-5xl text-border">Executive Committee</h1> */}
                    <h2 className="mb-10 text-3xl font-extrabold tracking-tight leading-none md:text-4xl w-full lg:text-4xl text-teal-600 md:w-4/5">
                      Design Team
                    </h2>

                    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-10 justify-items-center w-full md:w-3/4">
                      {filteredDesignTeam !== null &&
                        filteredDesignTeam?.length > 0 &&
                        filteredDesignTeam?.map((member, index) => (
                          <ExecomCard e={member} />
                        ))}
                    </section>
                    {isPending_design_team && (
                      <div className="p-2 w-full text-center text-black relative flex justify-center">
                        <LocalLoader />
                      </div>
                    )}
                    {error_design_team && (
                      <div className="p-2 w-full text-center text-black">
                        {error}
                      </div>
                    )}
                    {/* {!error_design_team && filteredDesignTeam?.length === 0 && !isPending_design_team && <div className='p-2 text-center text-black w-full'>No members.</div>} */}
                  </div>
                )}
              {!error_media_team &&
                filteredMediaTeam?.length !== 0 &&
                !isPending_media_team && (
                  <div className="flex flex-wrap mt-24 px-6 place-content-center">
                    {/* <h1 className="mb-4 text-3xl font-extrabold tracking-tight leading-none md:text-4xl 'w-full lg:text-5xl text-border">Executive Committee</h1> */}
                    <h2 className="mb-10 text-3xl font-extrabold tracking-tight leading-none md:text-4xl w-full lg:text-4xl text-teal-600 md:w-4/5">
                      Media Team
                    </h2>

                    <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-10 justify-items-center w-full md:w-3/4">
                      {filteredMediaTeam !== null &&
                        filteredMediaTeam?.length > 0 &&
                        filteredMediaTeam?.map((member, index) => (
                          <ExecomCard e={member} />
                        ))}
                    </section>
                    {isPending_media_team && (
                      <div className="p-2 w-full text-center text-black relative flex justify-center">
                        <LocalLoader />
                      </div>
                    )}
                    {error_media_team && (
                      <div className="p-2 w-full text-center text-black">
                        {error}
                      </div>
                    )}
                    {/* {!error_media_team && filteredMediaTeam?.length === 0 && !isPending_media_team && <div className='p-2 text-center text-black w-full'>No members.</div>} */}
                  </div>
                )}
            </div>
          ) : (
            <LocalLoader />
          )}
        </TabPanel>
      </Box>
    </div>
  );
};

export default ExecomPage;
