import React from 'react';
import seedsLogo from '../assets/seedslogo.svg';

const SeedsPage = () => {

  const seeddsDetails = [
    {
      title: 'Mission',
      description:
     "The Startup Enabled Entrepreneurship Development Space (SEEDS 97) is a space in TKM College of Engineering, Kollam, developed with the major support of the Alumni of its 1997 batch. The vision of SEEDS 97 is to create a sustainable ecosystem that fosters socially responsible startups and nurtures an entrepreneurial community among students.",
    },
    {
      title: 'Facilities',
      description:
      "The Startup Enabled Entrepreneurship Development Space (SEEDS 97) is space in TKM College of Engineering, Kollam, developed with the major support of the Alumni of its 1997 batch. The SEEDS 97 consists of three spaces – A Business Incubation Centre having facility for registered companies to function their office, a workshop space for Innovation and Entrepreneurship Development Cell (IEDC Workshop) for students of TKMCE to perform their innovative and entrepreneurial activities and a Conference Space which can be used by both IEDC and the registered companies. The operations and maintenance of the SEEDS 97 will be jointly done by 1997 Batch Alumni and the College",
    },
    {
      title: 'Objectives',
      description:
      "The objectives of SEEDS ’97 are to promote on-campus startup operations, promote incubation of innovative technological startups, leverage alumni network to provide support for startup and entrepreneurship development, and facilitate corporate collaborations and business networking opportunities for aspiring student entrepreneurs.",
    },
  ];
  
  return (
    <div className="bg-[#000123]  flex flex-col items-center justify-center">

      <div className="flex flex-col items-center justify-center">
        <img src={seedsLogo} className='w-[1500px] h-[200px] md:h-[541.63px]'/>
      </div>
      <div className="flex flex-col items-center gap-2 my-2">
        <p className='text-[#68CAC9] text-lg'>To see our operational policy</p>
        <a className='rounded-full bg-[#68CAC9] text-[#02043e] font-semibold px-4 py-1 cursor-pointer'
          href='Seeds_Operational_Policy.pdf'
          target='_blank'
          download
        >
          Download
        </a>
      </div>
      
      <div className="md:px-12 py-12 text-black ml-12 mr-12">
        <div className="text-black flex flex-col gap-10 mb-5">
          {seeddsDetails.map((position, index) => (
            <div
              key={index}
              className="bg-[#68CAC9]  rounded-lg shadow-lg p-6 "
            >
              <p className="font-bold text-xl md:text-2xl mb-4 uppercase text-[#02043e]">{position.title}</p>
              <p className="break-words text-lg md:text-md text-black">{position.description}</p>
            </div>
          ))}
        </div>

       
      </div>
    </div>
  );
};

export default SeedsPage;
